import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components";

import SEO from '../../components/SEO';
import Header from '../../components/Header';
import Nav from '../../components/Nav';

import calendario from '../../assets/images/calendario.svg';

const ArticulosStyles = styled.div`

  .HeaderTextos {
    padding: 0 1rem 1rem 1rem;
    width: 70%;
    margin: 0 auto;

    p {
      padding: 1rem;
      background-color: var(--color_5);
      border-radius: 3px;
      font-style: italic;
      box-shadow: 1px 1px 2px 2px whitesmoke;
    }

    @media (max-width: 800px) {
      width: 90%;
    }
  }

  .searchBox {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 2rem;
    width: 70%;

    input {
      margin: 0 auto;
      border: 2px solid var(--color_3);
      outline: none;
      border-radius: 5px;
      padding: 8px;
      width: 80%;
    }

    input:focus {
      border: 2px solid var(--color_7);
    }
  }

  .ArticulosGrid {
    margin-top: 2rem;
    padding-bottom: 2rem;

    width: 70%;
    margin: 0 auto;
    display: grid;
    align-self: center;
    grid-gap: 2rem;
    grid-template-rows: repeat(auto-fill, max(1fr, 250px));

    @media (max-width: 800px) {
      width: 90%;
    }
  }

  .TarjetaArticuloStyle {
    //border: 1px solid var(--color_1);
    padding: 3rem;
    background-color: var(--color_5);
    transition: 0.3s;

    :hover {
      background-color: var(--color_6);
      scale: 1.01;
    }

    .tarjeta-titulo {
      //background-color: var(--color_0);
      display: inline-block;
      padding: 3px 0px 5px 0px;
      margin-bottom: 3px;
      font-size: 2.1rem;
      font-weight: bold;
    }

    .tarjeta-fecha {
      display: flex;
      height: 3rem;
      align-items: center;
      color: var(--color_negro_2);
      img {
        padding-right: 0.5rem;
        padding-bottom: 1px;
      }
    }

    .texto-resumen {
      font-size: 2.1rem;

    }

    .link2-mas {
      text-decoration: underline;
      text-decoration-color: var(--color_0);
      font-size: 2rem;
    }
  }
`;

const AllArticles = props => {
  const { data } = props;
  const allPosts = data.allSanityPost.nodes;

  const emptyQuery = ""

  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  })

  const handleInputChange = event => {
    const query = event.target.value
    const { data } = props
    const posts = data.allSanityPost.nodes || []
    const filteredData = posts.filter(post => {
      //const id = post.node.id;
      const title = post.title;
      //const slug = post.slug.current;
      const author = post.author.name;
      const categories = []; 
      post.categories.forEach(category => {
        categories.push(category.title);
      });
      const resumen = post.body[0].children[0].text;
      return (
        title.toLowerCase().includes(query.toLowerCase()) ||
        author.toLowerCase().includes(query.toLowerCase()) ||
        resumen.toLowerCase().includes(query.toLowerCase()) ||
        (categories &&
          categories
            .join("")
            .toLowerCase()
            .includes(query.toLowerCase()))
      )
    })

    setState({
      query,
      filteredData,
    })
  }

  const { filteredData, query } = state
  const hasSearchResults = filteredData && query !== emptyQuery
  const posts = hasSearchResults ? filteredData : allPosts

  return (
    <>
      <SEO />
      <Header />
      <Nav categorias={data.categorias.nodes}/>
      {/* in my site I wrap each page with a Layout and SEO component which have 
    been omitted here for clarity and replaced with a React.fragment --> */}
      {/*in-line css for demo purposes*/}
      <ArticulosStyles>
        <div className="HeaderTextos">
          <p>Búsqueda de artículos</p>
        </div>
        <div className="searchBox">
          <input
            className="searchInput"
            type="text"
            aria-label="Search"
            placeholder="Escribí aquí..."
            onChange={handleInputChange}
          />
        </div>
        <div className="ArticulosGrid">
          {posts.map((node) => {
            var fecha_str = node.publishedAt.toString()
            var year = fecha_str.slice(0, 4);
            var month = fecha_str.slice(5, 7);
            var day = fecha_str.slice(8, 10); 
            const excerpt = node.body[0].children[0].text
            const slug = node.slug.current
            const title = node.title
            return (
              <Link key={"tarjeta_" + node.id} to={`/articulo/${year}/${month}/${day}/${node.slug.current}`} style={{ textDecoration: 'none' }}>
                <div className="TarjetaArticuloStyle" key={"tarj_resumen" + node.id}>
                  <h3 className="tarjeta-titulo" key={"h2_resumen" + node.id}>{title}</h3>
                  <div className="tarjeta-fecha" key={"div_resumen" + node.id}>
                    <img src={calendario} align="center" alt="Calendario" height="15px"/> 
                    <h4 key={"h4_resumen" + node.id}>{day}-{month}-{year}</h4>
                  </div>
                  <p className="texto-resumen" key={"p_resumen" + node.id}>{excerpt}</p>
                  <p key={"p2_resumen" + node.id}>
                    <span key={"span2_resumen" + node.id} className="link2-mas">Leer más...</span>
                  </p>
                </div>
              </Link>
            )
          })}
        </div>
      </ArticulosStyles>
    </>
  )
}

export default AllArticles

export const pageQuery = graphql`
  query {
    allSanityPost(sort: {fields: publishedAt, order: DESC}) {
      totalCount
      nodes {
        title
        id
        slug {
          current
        }
        author {
          name
        }
        publishedAt
        body {
          children {
            text
          }
        }
        categories {
          title
        }
      }
    }
    categorias: allSanityCategory {
      nodes {
        title
      }
    }
  }
`